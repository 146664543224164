import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const getRatingEndpoint = (productId) =>
  `${Endpoints.PRODUCTS}/${productId}${Endpoints.RATINGS}`;

const getRating = async (productId) => {
  const URL = getRatingEndpoint(productId);
  try {
    const response = await getAxiosInstance().get(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    alert("Failed to get rating.");
    console.error(error);
  }
};

const addRating = async (productId, ratingDTO) => {
  const URL = getRatingEndpoint(productId);
  try {
    const response = await getAxiosInstance().post(URL, ratingDTO);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    alert("Failed to submit rating.");
    console.error(error);
  }
};

const getRecommendations = async (userId) => {
  try {
    const response = await getAxiosInstance().get(
      `${Endpoints.RECOMMENDATIONS}/${userId}`
    );

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    console.error(error);
  }
};

export const ratingsApi = {
  addRating,
  getRating,
  getRecommendations,
};
