import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css'; // Ensure CSS path is correct

const HeroSection = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true); // Triggers the animation when the component mounts
    }, []);

    return (
        <div className="hero-section">
            <h1 className={`hero-title ${animate ? 'slide-in' : ''}`}>
                BRING BACK THE GREAT MUSIC!
            </h1>
            <Link to="/shop" className="hero-button">
                Shop Now
            </Link>
        </div>
    );
}

export default HeroSection;
