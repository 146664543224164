import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 45.9432, // Center of Romania
  lng: 24.9668,
};

const vinylShops = [
  {
    lat: 46.770165,
    lng: 23.5912569,
    name: "Dusty Sounds Record Shop",
    link: "https://www.google.com/maps/place/Dusty+Sounds+Record+Shop/@46.770165,23.5912569,17z/data=!3m1!4b1!4m6!3m5!1s0x47490c27079b5197:0x6f2b80584aee13fd!8m2!3d46.770165!4d23.5938318!16s%2Fg%2F11c5rpgvzp?hl=ro&entry=ttu",
  },
  {
    lat: 46.7754245,
    lng: 23.6082513,
    name: "Vinvinyl",
    link: "https://www.google.com/maps/place/Vinvinyl/@46.7754245,23.6082513,17z/data=!3m1!4b1!4m6!3m5!1s0x47490d1a4d9ac54f:0xfe4d3d764f7da80c!8m2!3d46.7754245!4d23.6108262!16s%2Fg%2F11trfrdkqg?hl=ro&entry=ttu",
  },
  {
    lat: 46.7676503,
    lng: 23.5898903,
    name: "Melo Melanj",
    link: "https://www.google.com/maps/place/Melo+Melanj/@46.7677994,23.5898237,17z/data=!4m14!1m7!3m6!1s0x47490d1e9b3da579:0x2a6d6bc18b1c28ec!2sMelo+Melanj!8m2!3d46.7677994!4d23.5898237!16s%2Fg%2F11q38z4rjm!3m5!1s0x47490d1e9b3da579:0x2a6d6bc18b1c28ec!8m2!3d46.7677994!4d23.5898237!16s%2Fg%2F11q38z4rjm?hl=ro&entry=ttu",
  },
];

const MapComponent = () => {
  const handleMarkerClick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={6} center={center}>
      {vinylShops.map((shop, index) => (
        <Marker
          key={index}
          position={{ lat: shop.lat, lng: shop.lng }}
          title={shop.name}
          onClick={() => handleMarkerClick(shop.link)}
        />
      ))}
    </GoogleMap>
  );
};

export default MapComponent;
