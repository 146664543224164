import React, { useState, useEffect, useMemo } from "react";
import MapComponent from "./Map";
import { factsApi } from "../../api";
import "./ExplorePage.css";

const monthsMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const ExplorePage = () => {
  const [facts, setFacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = useMemo(() => new Date(), []);

  useEffect(() => {
    const fetchFact = async () => {
      try {
        const monthDay = `${
          monthsMap[today.getMonth() + 1]
        } ${today.getDate()}`;

        const data = await factsApi.getFact(monthDay);

        setFacts(data.facts);

        setLoading(false);
      } catch (error) {
        setError("Error fetching fact");
        setLoading(false);
      }
    };
    fetchFact();
  }, [today]);

  return (
    <div className="explore-container">
      <h1>Explore</h1>
      <div>
        <h2>Did You Know?</h2>
        {loading ? (
          <p>Loading facts...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div>
            <h3>On this day... </h3>
            {facts.map((fact, idx) => (
              <p key={fact.year + idx}>
                <b>In year {fact.year}</b> - {fact.description}
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="map">
        <h2>Vinyl Shops in Romania</h2>
        <MapComponent />
      </div>
    </div>
  );
};

export default ExplorePage;
