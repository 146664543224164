import React from "react";
import "./BestSeller.css";

const BestSeller = () => {
  const products = [
    {
      name: "Born To Die - Paradise Edition Vinyl",
      price: "RON 180",
      imageUrl: require("../../Pictures/Lana.jpeg"),
      link: "/shop",
    },
    {
      name: "SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER",
      price: "",
      imageUrl: require("../../Pictures/Irina.jpeg"),
      link: "/shop",
      special: true,
    },
    {
      name: 'RUSH! (Are U Coming?) - 12" Splatter Vinyl',
      price: "RON 230",
      imageUrl: require("../../Pictures/Maneskin.jpeg"),
      link: "/shop",
    },
    {
      name: "The Car - Vinyl",
      price: "RON 150",
      imageUrl: require("../../Pictures/Arctic.jpeg"),
      link: "/shop",
    },
  ];

  return (
    <div className="best-seller">
      <h2 className="title">BEST SELLER</h2>
      <div className="product-container">
        {products.map((product, index) => (
          <div
            className={`product ${product.special ? "special" : ""}`}
            key={index}
          >
            <div className="product-image-container">
              <img src={product.imageUrl} alt={product.name} />
              <div className={`overlay ${product.special ? "special" : ""}`}>
                <a
                  href={product.link}
                  className={`${
                    product.special ? "more-button" : "buy-now-button"
                  }`}
                >
                  {product.special ? product.name : "Buy Now"}
                </a>
              </div>
            </div>
            {!product.special && (
              <>
                <h5>{product.name}</h5>
                <p>{product.price}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSeller;
