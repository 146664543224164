import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const registerUser = async (userData) => {
  try {
    const response = await getAxiosInstance().post(
      Endpoints.REGISTER,
      userData
    );
    return {
      token: response.data,
      success: true,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getUserDetails = async () => {
  try {
    const response = await getAxiosInstance().get(Endpoints.USER_DETAILS);
    return response;
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const updateUserDetails = async (userData) => {
  try {
    const response = await getAxiosInstance().put(
      Endpoints.USER_DETAILS,
      userData
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const usersApi = {
  registerUser,
  getUserDetails,
  updateUserDetails,
};
