import React, { useEffect, useState } from "react";
import { Button, OrderItem } from "../../shared-components";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./OrderPlaced.module.css";
import { ordersApi } from "../../api";
import { useProductsContext } from "../../context";

const OrderPlaced = () => {
  const navigate = useNavigate();
  const { orderIds } = useParams();

  const [order, setOrder] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);

  const { products } = useProductsContext();

  useEffect(() => {
    const fetchOrders = async () => {
      const data = await ordersApi.getOrder(orderIds);
      setOrder(data);

      const orderItemProducts = data.orderItemDtoList.map((orderItem) => {
        const product = products.find(
          (product) => product.id === orderItem.productId
        );

        return {
          image: product.imageId,
          name: product.name,
          price: product.price,
          quantity: orderItem.quantity,
        };
      });

      setOrderProducts(orderItemProducts);
    };
    fetchOrders();
  }, [orderIds, products]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>
        <div className={styles.checkmark}>&#10003;</div>

        <h2 className={styles.heading}>Thank you for your purchase</h2>

        <p className={styles.text}>
          We've received your order and it will ship in 5-7 business days.
        </p>
      </div>

      <div className={styles.orderSummary}>
        <h3 className={styles.summaryTitle}>Order Summary</h3>
        {orderProducts.map(({ name, quantity, image, price }) => (
          <OrderItem
            key={name}
            name={name}
            quantity={quantity}
            price={price}
            image={image}
          />
        ))}
        <div className={styles.total}>
          <p>Total</p>
          <p> {order.totalPrice} RON</p>
        </div>
      </div>

      <Button
        text="Back to Home"
        onClick={() => navigate("/home", { replace: true })}
      />
    </div>
  );
};

export default OrderPlaced;
