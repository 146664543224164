import React from "react";
import { useLoginContext } from "../../context";
import { useNavigate } from "react-router-dom";
import styles from "./Account.module.css";

const Account = () => {
  const { userInfo } = useLoginContext();

  const navigate = useNavigate();

  const navigateOnRole = {
    BUYER: () => navigate("/buyer-dashboard"),
    SELLER: () => navigate("/seller-dashboard"),
    ADMIN: () => navigate("/buyer-dashboard"),
  };

  const getNavigateOnRole = (role) => role && navigateOnRole[role]();

  const navigateToLogin = () => navigate("/sign-in");

  if (userInfo.userRole)
    return (
      <div
        onClick={() => getNavigateOnRole(userInfo.userRole)}
        className={`${styles.pointer} ${styles.linkSize}`}
      >
        My account
      </div>
    );
  else
    return (
      <div onClick={navigateToLogin} className={`${styles.pointer} ${styles.linkSize}`}>
        Login
      </div>
    );
};

export default Account;
