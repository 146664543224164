import React, { useEffect, useState, useMemo } from "react";
import { paymentApi } from "../../api";
import { Chart } from "react-charts";
import { useLoginContext } from "../../context";
import styles from "./SellerReports.module.css";

const SellerReports = () => {
  const [transactions, setTransactions] = useState([]);

  const { userInfo } = useLoginContext();

  const { userId } = userInfo;

  const data = [
    {
      label: "Transaction payment",
      data: transactions || [],
    },
  ];

  const primaryAxis = useMemo(
    () => ({
      getValue: (transaction) => new Date(transaction.createdDate),
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (transaction) => transaction.amount,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTransactions = async () => {
      const data = await paymentApi.getTransactions();

      const specificUserTransactions = data.filter(
        (transactionDTO) => transactionDTO.sellerId === userId
      );

      setTransactions(specificUserTransactions);
    };
    fetchTransactions();
  }, [userId]);

  return (
    <div className={styles.reportsWrapper}>
      <h2>Reports</h2>
      {transactions.length > 0 && (
        <div className={styles.chartContainer}>
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SellerReports;
