import React, { useEffect, useState } from "react";
import "./ProductManagement.css";
import { useLoginContext, useProductsContext } from "../../context"; // Import context
import { productsApi, spotifyApi } from "../../api";
import { useErrorBoundary } from "react-error-boundary";
import { useUserProducts } from "../../hooks";

const ProductManagement = () => {
  const { userInfo } = useLoginContext(); // Access authenticated user and userId
  const { userId } = userInfo; // Get userId from context

  const { setProducts, products, removeProduct } = useProductsContext();

  const { userProducts } = useUserProducts(products);

  const { showBoundary } = useErrorBoundary();

  const [formData, setFormData] = useState({
    name: "",
    artist: "",
    genre: "",
    price: "",
    availability: "",
    imageId: "",
    popularity: "",
    quantity: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      console.error("Cannot submit without userId.");
      alert("User ID is not available.");
      return;
    }

    try {
      const { mediumImage, spotifyAlbumId } =
        await spotifyApi.getAlbumCoverAndId(formData.artist, formData.name);

      const productData = {
        ...formData,
        sellerId: userId, // Include userId in the payload
        imageId: mediumImage,
        spotifyAlbumId,
      };

      const newProduct = await productsApi.addProduct(productData);

      setProducts((previousProducts) => [...previousProducts, newProduct]);

      setFormData({
        name: "",
        artist: "",
        genre: "",
        price: "",
        availability: "",
        imageId: "",
        popularity: "",
        quantity: "",
      });
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div className="product-management">
      <h2>Manage Products</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="artist"
          placeholder="Artist"
          value={formData.artist}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="genre"
          placeholder="Genre"
          value={formData.genre}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="price"
          placeholder="Price"
          value={formData.price}
          onChange={handleChange}
          required
        />

        <select
          name="availability"
          value={formData.availability}
          onChange={handleChange}
        >
          <option value="In stock">In Stock</option>
          <option value="Out of stock">Out of Stock</option>
        </select>
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={formData.quantity}
          onChange={handleChange}
          required
        />
        <button type="submit">Add Product</button>
      </form>

      <h3>Existing Products</h3>
      <ul>
        {userProducts.map((product) => (
          <li key={product.id}>
            {product.name} - {product.artist}
            <button onClick={() => removeProduct(product.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductManagement;
