import React from "react";
import { Button } from "../../shared-components";
import { useNavigate } from "react-router-dom";

const ErrorPage = ({ error }) => {
  const navigate = useNavigate();
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <Button text="Go back home" onClick={() => location.reload()} />
    </div>
  );
};

export default ErrorPage;
