import { useEffect, useState } from "react";

export const useProductFiltering = (products, filters) => {
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    const appliedFilters = products.filter((product) => {
      return (
        (filters.genre === "" || product.genre === filters.genre) &&
        (filters.artist === "" || product.artist === filters.artist) &&
        (filters.availability === "" ||
          product.availability === filters.availability) &&
        (filters.minPrice === "" ||
          product.price >= parseFloat(filters.minPrice)) &&
        (filters.maxPrice === "" ||
          product.price <= parseFloat(filters.maxPrice)) &&
        (filters.minRating === "" ||
          product.rating >= parseFloat(filters.minRating))
      );
    });

    setFilteredProducts(appliedFilters);
  }, [filters, products]);

  return { filteredProducts };
};
