import React, { useState, useEffect } from "react";
import "./ShopPage.css";
import { Notification, Product } from "../../shared-components";
import {
  useCartContext,
  useLoginContext,
  useProductsContext,
  useWishlistContext, // Import the wishlist context
} from "../../context";
import { useProductFiltering, useProductSorting } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ShopPage = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [sortType, setSortType] = useState("Relevance");
  const [filters, setFilters] = useState({
    genre: "",
    artist: "",
    availability: "",
    minPrice: "",
    maxPrice: "",
    minRating: "",
  });
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ message: "", gif: "" });

  const navigate = useNavigate();

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const { paginatedProducts, setCurrentPage, pages, genres, artists } =
    useProductsContext();

  const { addCartItem } = useCartContext();
  const { userInfo } = useLoginContext();
  const { addToWishlist, removeFromWishlist, isInWishlist } =
    useWishlistContext();

  useEffect(() => {
    if (paginatedProducts.length > 0) {
      setLoading(false);
    }
  }, [paginatedProducts]);

  const { filteredProducts } = useProductFiltering(paginatedProducts, filters);
  const { sortedProducts } = useProductSorting(filteredProducts, sortType);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const addProductToCart = (productId, quantity) => {
    if (userInfo.isLoggedIn) {
      addCartItem(productId, quantity);
      toast.success(
        <Notification
          gif="https://media.giphy.com/media/111ebonMs90YLu/giphy.gif"
          message="Item added to cart"
        />,
        {
          autoClose: 2000,
        }
      );
    } else {
      navigate("/sign-in");
    }
  };

  const toggleWishlistItem = (product) =>
    isInWishlist(product)
      ? removeFromWishlist(product)
      : addToWishlist(product);

  if (loading) {
    return <div>Loading products...</div>;
  }

  return (
    <div className="shop-page">
      {isFilterVisible && (
        <div
          className={`filter-panel ${isFilterVisible ? "visible" : "hidden"}`}
        >
          <h2>Filter</h2>
          <div>
            <label>Music Genre</label>
            <select
              name="genre"
              value={filters.genre}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {genres.map((genre) => (
                <option value={genre} key={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Artist</label>
            <select
              name="artist"
              value={filters.artist}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {artists.map((artist) => (
                <option value={artist} key={artist}>
                  {artist}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Availability</label>
            <select
              name="availability"
              value={filters.availability}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              <option value="In stock">In Stock</option>
              <option value="Out of stock">Out of Stock</option>
            </select>
          </div>
          <div>
            <label>Price Range</label>
            <input
              type="number"
              name="minPrice"
              placeholder="Min"
              value={filters.minPrice}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max"
              value={filters.maxPrice}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label>Minimum Rating</label>
            <select
              name="minRating"
              value={filters.minRating}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
      )}

      <div
        className={`toggle-filter ${isFilterVisible ? "visible" : "hidden"}`}
        onClick={toggleFilter}
      >
        {isFilterVisible ? "◄" : "►"}
      </div>
      <div className={`shop-content ${isFilterVisible && "visible"}`}>
        <div className="sort-filter">
          <select
            value={sortType}
            onChange={(e) => setSortType(e.target.value)}
          >
            <option>Relevance</option>
            <option>Alphabetically Ascending</option>
            <option>Alphabetically Descending</option>
            <option>Price Ascending</option>
            <option>Price Descending</option>
            <option>Most Popular</option>
            <option>Newest</option>
          </select>
        </div>
        {sortedProducts.length > 0 ? (
          <>
            <div className="product-list">
              {sortedProducts.map((product) => {
                const { id } = product;

                return (
                  <Product
                    key={id}
                    navigateToProduct={() => navigateToProduct(id)}
                    addToCart={() => addProductToCart(id, 1)}
                    toggleWishlistItem={() => toggleWishlistItem(product)}
                    {...product}
                  />
                );
              })}
            </div>
            <div className="pagination">
              {pages.map((number) => (
                <button key={number} onClick={() => setCurrentPage(number)}>
                  {number}
                </button>
              ))}
            </div>
          </>
        ) : (
          <div>No results; please filter again</div>
        )}
      </div>
    </div>
  );
};

export default ShopPage;
