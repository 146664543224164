import React from "react";
import { useLoginContext } from "../../context";
import { Navigate } from "react-router-dom";

const Protected = ({ children, roles, path }) => {
  const { userInfo } = useLoginContext();

  if (!roles.includes(userInfo.userRole)) {
    if (path === "/cart") return <Navigate to="/sign-in" />;

    return <Navigate to="/home" />;
  }

  return children;
};

export default Protected;
