import { useEffect, useState } from "react";

const AvailableSortings = {
  "Alphabetically Ascending": (a, b) => b.name.localeCompare(a.name),
  "Alphabetically Descending": (a, b) => a.name.localeCompare(b.name),
  "Price Ascending": (a, b) => b.price - a.price,
  "Price Descending": (a, b) => a.price - b.price,
  "Most Popular": (a, b) => (a, b) => b.popularity - a.popularity,
  Newest: (a, b) => b.id - a.id,
};

const emptyFunction = () => {};

const getSorting = (sortType) =>
  sortType && AvailableSortings[sortType]
    ? AvailableSortings[sortType]
    : emptyFunction;

export const useProductSorting = (products, sortType) => {
  const [sortedProducts, setSortedProducts] = useState(products);

  useEffect(() => {
    const sortingFunction = getSorting(sortType);
    const appliedSorting = products.sort(sortingFunction);

    setSortedProducts(appliedSorting);
  }, [sortType, products]);

  return { sortedProducts };
};
