import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const addCartItem = async (productId, quantity) => {
  const URL = `${Endpoints.CART}${Endpoints.ADD_CART_ITEM}?productId=${productId}&quantity=${quantity}`;

  try {
    const response = await getAxiosInstance().post(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

const getCartItems = async () => {
  const URL = `${Endpoints.CART}${Endpoints.LIST_CART_ITEMS}`;
  try {
    const response = await getAxiosInstance().get(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

const removeCartItem = async (productId) => {
  const URL = `${Endpoints.CART}${Endpoints.DELETE_CART_ITEM}?productId=${productId}`;
  try {
    const response = await getAxiosInstance().delete(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

const updateCartItem = async (productId, quantity) => {
  const URL = `${Endpoints.CART}${Endpoints.UPDATE_CART_ITEM}?productId=${productId}&quantity=${quantity}`;
  try {
    const response = await getAxiosInstance().patch(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

export const cartApi = {
  addCartItem,
  getCartItems,
  removeCartItem,
  updateCartItem,
};
