import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { generatePassword } from "../../utils/passwordGenerator";
import { usersApi } from "../../api";
import "./SellerRegistrationForm.css";
import { useLoginContext } from "../../context";
import { Button } from "../../shared-components";

const SellerRegistrationForm = () => {
  const [userType, setUserType] = useState("individual");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    cui: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    role: "SELLER",
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { handleToken } = useLoginContext();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "password") {
      const result = zxcvbn(e.target.value);
      setPasswordStrength(result.score);
    }
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    setFormData({ ...formData, password });
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await usersApi.registerUser(formData);
    if (result.success) {
      handleToken(result.token);
      navigate("/seller-dashboard");
    }
  };

  return (
    <>
      <div className="seller-registration-form">
        <h2>Seller Registration Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="userType">User Type</label>
            <select
              id="userType"
              name="userType"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="password-label">
              Password:
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span className="password-toggle" onClick={toggleShowPassword}>
                  {showPassword ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </span>
              </div>
              <span
                className="generate-password"
                onClick={handleGeneratePassword}
              >
                Generate Strong Password
              </span>
            </label>
            {formData.password.length > 0 && (
              <div
                className={`password-strength ${
                  ["weak", "medium", "strong", "strong", "strong"][
                    passwordStrength
                  ]
                }`}
              >
                {
                  [
                    "Weak password",
                    "Medium password",
                    "Good password",
                    "Strong password",
                    "Very strong password",
                  ][passwordStrength]
                }
              </div>
            )}
          </div>

          <div className="form-group">
            <label>Confirm password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>

          {userType === "company" && (
            <div className="company-group">
              <div className="form-group">
                <label htmlFor="companyName">Company name</label>
                <input
                  id="companyName"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="cui">CUI</label>
                <input
                  id="cui"
                  type="text"
                  name="cui"
                  value={formData.cui}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              id="address"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              id="city"
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="state">State</label>
            <input
              id="state"
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="zipcode">Zip code</label>
            <input
              id="zipcode"
              type="text"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone number</label>
            <input
              id="phone"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <Button text="Register your business!" type="submit" />
        </form>
      </div>
    </>
  );
};

export default SellerRegistrationForm;
