import React, { useState, useEffect } from "react";
import { usersApi } from "../../api";
import "./BuyerDetails.css"; // Assuming you have a separate CSS file for styling

const BuyerDetails = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });

  const [newUserData, setNewUserData] = useState({ ...userData }); // Initial copy of the user's data
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await usersApi.getUserDetails();
        if (response.success && response.data) {
          setUserData(response.data);
          setNewUserData(response.data); // Initialize new user data with the fetched data
        } else {
          console.error("Failed to fetch user details:", response.error);
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await usersApi.updateUserDetails(newUserData);
      if (result.success) {
        alert("Details updated successfully!");
        setUserData(newUserData); // Update displayed data after successful update
      } else {
        alert("Failed to update details.");
      }
    } catch (error) {
      console.error("Error updating details:", error.message);
      alert("Failed to update details.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="buyer-details-container">
      <h2>Your Details</h2>
      <form onSubmit={handleSubmit} className="buyer-details-form">
        <div className="details-column">
          <h3>Current Details</h3>
          <p>First Name: {userData.firstName}</p>
          <p>Last Name: {userData.lastName}</p>
          <p>Email: {userData.email}</p>
          <p>Telephone: {userData.telephone}</p>
          <p>Address Line 1: {userData.addressLine1}</p>
          <p>Address Line 2: {userData.addressLine2}</p>
          <p>City: {userData.city}</p>
          <p>State: {userData.state}</p>
          <p>Country: {userData.country}</p>
          <p>Zip Code: {userData.zipCode}</p>
        </div>
        <div className="details-column">
          <h3>New Details</h3>
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={newUserData.firstName || ""}
            onChange={handleChange}
          />
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={newUserData.lastName || ""}
            onChange={handleChange}
          />
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={newUserData.email || ""}
            onChange={handleChange}
          />
          <label>Telephone:</label>
          <input
            type="text"
            name="telephone"
            value={newUserData.telephone || ""}
            onChange={handleChange}
          />
          <label>Address Line 1:</label>
          <input
            type="text"
            name="addressLine1"
            value={newUserData.addressLine1 || ""}
            onChange={handleChange}
          />
          <label>Address Line 2:</label>
          <input
            type="text"
            name="addressLine2"
            value={newUserData.addressLine2 || ""}
            onChange={handleChange}
          />
          <label>City:</label>
          <input
            type="text"
            name="city"
            value={newUserData.city || ""}
            onChange={handleChange}
          />
          <label>State:</label>
          <input
            type="text"
            name="state"
            value={newUserData.state || ""}
            onChange={handleChange}
          />
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={newUserData.country || ""}
            onChange={handleChange}
          />
          <label>Zip Code:</label>
          <input
            type="text"
            name="zipCode"
            value={newUserData.zipCode || ""}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="update-button">
          Update Details
        </button>
      </form>
    </div>
  );
};

export default BuyerDetails;
