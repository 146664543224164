import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const addOrder = async (sessionId) => {
  const URL = `${Endpoints.ORDERS}/add?sessionId=${sessionId}`;
  try {
    const response = await getAxiosInstance().post(URL);

    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

const getOrders = async () => {
  try {
    const response = await getAxiosInstance().get(`${Endpoints.ORDERS}`);

    if (response.status >= 200 && response.status <= 299) return response.data;

    return [];
  } catch (error) {
    console.error(error);
  }
};

const getOrder = async (orderId) => {
  const URL = `${Endpoints.ORDERS}/${orderId}`;

  try {
    const response = await getAxiosInstance().get(URL);

    if (response.status >= 200 && response.status <= 299) return response.data;

    return [];
  } catch (error) {
    console.error(error);
  }
};

const getOrdersForBuyer = async (userId) => {
  const URL = `${Endpoints.ORDERS}${Endpoints.BUYER}/${userId}`;

  try {
    const response = await getAxiosInstance().get(URL);

    if (response.status >= 200 && response.status <= 299) return response.data;

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const ordersApi = {
  addOrder,
  getOrders,
  getOrder,
  getOrdersForBuyer,
};
