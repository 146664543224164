import React, { useState, useEffect } from "react";
import "./InventoryPage.css";
import { useProductsContext } from "../../context";
import { useUserProducts } from "../../hooks";

const InventoryPage = () => {
  const [loading, setLoading] = useState(true);

  const { products, setProductQuantity } = useProductsContext();

  const { userProducts } = useUserProducts(products);

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    products.length > 0 && setLoading(false);
  }, [products]);

  if (loading) return <div>Loading inventory...</div>;

  return (
    <div className="inventory-page">
      <h1>Inventory Management</h1>
      <table className="inventory-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Artist</th>
            <th>Genre</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {userProducts.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{product.artist}</td>
              <td>{product.genre}</td>
              <td>{product.price}</td>
              <td>{product.quantity}</td>
              <td>
                <input
                  type="number"
                  defaultValue={product.quantity || ""}
                  min="0"
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
                <button
                  onClick={async () => {
                    await setProductQuantity(product.id, quantity);
                  }}
                >
                  Update
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryPage;
