import React, { useState } from "react";
import "./CartProduct.css";
import { Button } from "../Button";
import { useCartContext } from "../../context";

const CartProduct = ({
  name,
  artist,
  imageId,
  price,
  handleRemove,
  itemQuantity,
  productId,
}) => {
  const { increaseCartItemQuantity, decreaseCartItemQuantity } =
    useCartContext();

  return (
    <div className="product-card">
      <img src={imageId} alt={name} />

      <div className="product-info">
        <div className="product-title">{name}</div>
        <div>{artist}</div>
        <div>Total price: {price}</div>
        <div className="product-quantity">
          <Button
            text="-"
            onClick={() => decreaseCartItemQuantity(productId)}
          />
          <span> {itemQuantity} </span>
          <Button
            text="+"
            onClick={() => increaseCartItemQuantity(productId)}
          />
        </div>

        <Button text="Remove item" onClick={handleRemove} />
      </div>
    </div>
  );
};

export default CartProduct;
