import React from "react";
import styles from "./OrderItem.module.css";

const OrderItem = ({ image, name, quantity, price }) => {
  return (
    <div className={styles.orderItem}>
      <img src={image} alt={name} className={styles.itemImage} />
      <div className={styles.itemDetails}>
        <p className={styles.itemText}>{name}</p>
        <div className={styles.itemPricing}>
          <p className={styles.itemText}>{price * quantity} RON</p>
          <p className={styles.itemText}>
            {quantity} x {price} RON
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
