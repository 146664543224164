import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { loginApi } from "../api";
import { deleteToken, getToken, setRefreshToken, setToken } from "../utils";

const initialState = {
  userInfo: {
    isLoggedIn: false,
    userRole: null,
    userMail: null,
    userId: null,
  },
  credentials: {
    email: "",
    password: "",
  },
  setUserInfo: () => {},
  refreshSession: () => {},
  handleToken: () => {},
};

export const LoginContext = createContext(null);

export const LoginContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(initialState.userInfo);
  const [credentials, setCredentials] = useState(initialState.credentials);

  const decodeToken = useCallback(
    (token) => JSON.parse(atob(token.split(".")[1])),
    []
  );

  useEffect(() => {
    const token = getToken();
    const role = localStorage.getItem("userRole");

    if (token) {
      const decoded = decodeToken(token);
      const mail = decoded.sub;
      const userId = decoded.userId;

      if (role) {
        setUserInfo({
          isLoggedIn: true,
          userRole: role,
          userMail: mail,
          userId: userId,
        });
      }
    }
  }, [decodeToken]);

  const handleToken = (token) => {
    const decoded = decodeToken(token);

    const role = decoded.authorities[0];
    const mail = decoded.sub;
    const userId = decoded.userId;

    setUserInfo({
      isLoggedIn: true,
      userRole: role,
      userMail: mail,
      userId: userId,
    });

    setToken(token);
    setRefreshToken(token);
    localStorage.setItem("userRole", role);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const token = await loginApi.getToken(credentials);
      handleToken(token);

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLogout = () => {
    alert("Logout successful");
    setUserInfo({
      isLoggedIn: false,
      userRole: null,
      userMail: null,
      userId: null,
    });

    localStorage.removeItem("userRole");
    deleteToken();
  };

  const refreshSession = async () => {
    const token = await loginApi.refreshSessionToken(getToken());
    setToken(token);
  };

  return (
    <LoginContext.Provider
      value={{
        userInfo,
        setUserInfo,
        handleLogin,
        handleLogout,
        setCredentials,
        refreshSession,
        handleToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => useContext(LoginContext);
