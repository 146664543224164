import React from "react";
import styles from "./Product.module.css";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";

const Product = ({
  id,
  imageId,
  name,
  artist,
  price,
  availability,
  rating,
  addToCart,
  toggleWishlistItem,
}) => {
  const navigate = useNavigate();

  const navigateToProduct = (productId) => navigate(`/product/${productId}`);

  return (
    <div key={id} className={styles.productItem}>
      <img src={imageId} alt={name} className={styles.productImage} />
      <h2 onClick={() => navigateToProduct(id)} className={`${styles.pointer}`}>
        {name}
      </h2>
      <h4>{artist}</h4>
      <p>RON {price.toFixed(2)}</p>
      <h4>{availability}</h4>
      <h4>{rating.toFixed(2)} out of 5</h4>
      {!!addToCart && !!toggleWishlistItem && (
        <div className={styles.buttonContainer}>
          <Button
            onClick={addToCart}
            text="Add to Cart"
            disabled={availability === "Out of stock"}
          />
          <Button onClick={toggleWishlistItem} text="Add to Wishlist" />
        </div>
      )}
    </div>
  );
};

export default Product;
