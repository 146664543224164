export const API = "/api";

export const Endpoints = {
  PRODUCTS: `${API}/products`,
  LOGIN: `${API}/users/login`,
  REFRESH: `${API}/users/refresh`,
  REGISTER: `${API}/users/register`,
  RATINGS: `/ratings`,
  RECOMMENDATIONS: `${API}/recommendations/user`,
  CART: `/cart`,
  ADD_CART_ITEM: `/addCartItem`,
  LIST_CART_ITEMS: `/listCartItems`,
  DELETE_CART_ITEM: `/deleteCartItem`,
  UPDATE_CART_ITEM: `/updateCartItem`,
  ORDERS: `/orders`,
  PAYMENTS: `/payments`,
  TRANSACTIONS: `/transactions`,
  FACTS: `/facts`,
  BUYER: `/buyer`
};

export const SpotifyEndpoints = {
  AUTH: `/api/token`,
  SEARCH: `/search`,
  ALBUMS: `/albums`,
  TRACKS: `/tracks`,
};
