import React from "react";
import "./Button.css";

const Button = ({
  text,
  onClick = () => {},
  variant = "",
  type = "",
  disabled = false,
}) => {
  const buttonVariants = {
    navigation: "nav-button",
  };

  return (
    <button
      className={`default ${buttonVariants[variant] || ""}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
