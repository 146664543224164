import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const getProduct = async (productId) => {
  const URL = `${Endpoints.PRODUCTS}/${productId}`;
  try {
    const response = await getAxiosInstance().get(URL);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

const getProducts = async () => {
  try {
    const response = await getAxiosInstance().get(Endpoints.PRODUCTS);

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return [];
  } catch (error) {
    return error;
  }
};

const getProductsForUser = async (userId) => {
  try {
    const response = await getAxiosInstance().get(
      `${Endpoints.PRODUCTS}/user/${userId}`
    );

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return [];
  } catch (error) {
    return error;
  }
};

const addProduct = async (productDTO) => {
  try {
    const response = await getAxiosInstance().post(
      Endpoints.PRODUCTS,
      productDTO
    );

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return [];
  } catch (error) {
    console.error(error);
    alert("Failed to save product");
  }
};

const deleteProduct = async (productId) => {
  const URL = `${Endpoints.PRODUCTS}/${productId}`;
  try {
    await getAxiosInstance().delete(URL);
  } catch (error) {
    console.error("Error deleting product:", error);
  }
};

const updateProductQuantity = async (productId, quantity) => {
  const URL = `${Endpoints.PRODUCTS}/${productId}/stock`;
  try {
    await getAxiosInstance().put(URL, quantity);
  } catch (err) {
    return err;
  }
};

export const productsApi = {
  getProductsForUser,
  getProducts,
  getProduct,
  addProduct,
  deleteProduct,
  updateProductQuantity,
};
