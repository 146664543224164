import React, { useRef } from "react";
import "./ContactPage.css";
import { Button, Notification } from "../../shared-components";
import { toast } from "react-toastify";

const ContactPage = () => {
  const nameRef = useRef(null);
  const emailSubject = useRef(null);
  const emailBody = useRef(null);

  const sendMail = (e) => {
    e.preventDefault();
    const mail = {
      email: `Contact: ${emailSubject.current?.value}`,
      name: `Name: ${nameRef.current?.value}`,
      text: emailBody.current?.value,
    };
    const mailBody = mail.text;

    toast.success(
      <Notification
        gif="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExY2wyOWRsaXBtNG1lOGp2bDVzbWxrdTZua2xobzdnd3hsdHhldDhkaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/R6gvnAxj2ISzJdbA63/giphy.gif"
        message="Succesfully subscribed to Thunder Time Records"
      />,
      {
        autoClose: 2000,
      }
    );

    window.open(
      `mailto:
        thunder.time.records@gmail.com?
        Subject=${encodeURIComponent("Contact Thunder Time Records")} 
        &body=${encodeURIComponent(mailBody)}`,
      "_self"
    );
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <form className="contact-form" onSubmit={sendMail}>
        <label htmlFor="name"> Name: </label>
        <input id="name" type="text" name="name" required ref={nameRef} />

        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          name="email"
          required
          ref={emailSubject}
        />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" required ref={emailBody} />

        <Button text="Send" type="submit" />
      </form>
    </div>
  );
};

export default ContactPage;
