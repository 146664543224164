import React from "react";
import { useCartProducts } from "../../hooks";
import { Button, CartProduct } from "../../shared-components";
import "./Cart.css";
import {
  useCartContext,
  useLoginContext,
  useProductsContext,
} from "../../context";
import { ordersApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Cart = () => {
  const { cartProducts, totalCost } = useCartProducts();

  const { removeCartItem, fetchCartItems } = useCartContext();
  const { fetchProducts } = useProductsContext();
  const { userInfo } = useLoginContext();

  const navigate = useNavigate();

  const setOrder = async () => {
    try {
      const data = await ordersApi.addOrder(`User_${userInfo.userId}_session`);
      await fetchCartItems();
      await fetchProducts();

      if (data.success === true) {
        navigate(`/order-placed/${data.body}`);
      } else toast.error("Something went wrong, please try again later!");
    } catch (error) {
      toast.error(`Network problem ${error.message}`);
    }
  };

  return (
    <div className="cart-wrapper">
      <div className="product-wrapper">
        {cartProducts.length > 0 ? (
          cartProducts.map(({ name, artist, imageId, price, id, quantity }) => (
            <CartProduct
              key={id}
              name={name}
              artist={artist}
              imageId={imageId}
              itemQuantity={quantity}
              price={price * quantity}
              productId={id}
              handleRemove={() => removeCartItem(id)}
            />
          ))
        ) : (
          <div>No items at this moment in the cart</div>
        )}
      </div>

      <div className="cart-actions">
        {cartProducts.length > 0 && (
          <div className="cart-total">
            Total cart cost:
            <h3>{totalCost} RON</h3>
          </div>
        )}
        <Button
          text="Proceed to checkout"
          onClick={setOrder}
          disabled={cartProducts.length <= 0}
        />
      </div>
    </div>
  );
};

export default Cart;
