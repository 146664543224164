import Cookies from "js-cookie";

export const setRefreshToken = (refreshToken) => {
  Cookies.set("refreshToken", refreshToken, { expires: 1 });
};

export const getRefreshToken = () => {
  return Cookies.get("refreshToken");
};

export const deleteRefreshToken = () => {
  Cookies.remove("refreshToken");
};

export const setToken = (token) => {
  Cookies.set("token", token, { expires: 1 / 3 });
};

export const getToken = () => {
  return Cookies.get("token");
};

export const deleteToken = () => {
  Cookies.remove("token");
};
