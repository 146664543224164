// src/containers/WishlistPage.js
import React from "react";
import { useWishlistContext } from "../../context"; // Correct import path
import { Link } from "react-router-dom";
import styles from "./WishlistPage.module.css";

const WishlistPage = () => {
  const { wishlistItems, removeFromWishlist } = useWishlistContext();

  return (
    <div className={styles.wishlistDashboardContainer}>
      <h1>Your Wishlist</h1>
      {wishlistItems.length === 0 ? (
        <p>Your wishlist is empty.</p>
      ) : (
        <ul>
          {wishlistItems.map((product) => {
            const { id, name } = product;

            return (
              <li key={id}>
                <Link to={`/product/${id}`}>{name}</Link>
                <button onClick={() => removeFromWishlist(product)}>
                  Remove from Wishlist
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default WishlistPage;
