// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import App from "./App";
import {
  CartContextProvider,
  LoginContextProvider,
  ProductsContextProvider,
  WishlistContextProvider,
} from "./context";
import { combineProviders } from "./utils";
import { LoadScript } from "@react-google-maps/api";

const trackingId = "G-81ZGTZT5LD"; // Înlocuiește cu codul tău de măsurare
ReactGA.initialize(trackingId);

const root = ReactDOM.createRoot(document.getElementById("root"));

const AllProviders = combineProviders([
  [LoginContextProvider],
  [CartContextProvider],
  [ProductsContextProvider],
  [WishlistContextProvider],
]);

root.render(
  <React.StrictMode>
    <AllProviders>
      <LoadScript googleMapsApiKey="AIzaSyBZ080KqSv5IHtX42KYnU0_rc_q9K9q_EM">
        <App />
      </LoadScript>
    </AllProviders>
  </React.StrictMode>
);
