// src/Community/DonationSection.js

import React from 'react';
import './DonationSection.css';
import { FaPaypal, FaHandHoldingUsd } from 'react-icons/fa';

const DonationSection = () => {
    const handlePayPalDonation = () => {
        window.location.href = 'https://paypal.me/dariafrancioli';
    };

    const handleRevolutDonation = () => {
        window.location.href = 'https://revolut.me/alaska17';
    };

    return (
        <div className="donation-container">
            <div className="donation-header">
                <h1>Donate Now</h1>
                <p>The tunes won’t spin themselves! Your generosity fuels the vinyl voyage. Don’t wait, donate now!</p>
            </div>
            <div className="vinyl"></div>
            <div className="donation-buttons">
                <button className="button donate-button" onClick={handlePayPalDonation}>
                    <FaPaypal style={{ marginRight: '10px' }} />
                    Donate with PayPal
                </button>
                <button className="button donate-button" onClick={handleRevolutDonation}>
                    <FaHandHoldingUsd style={{ marginRight: '10px' }} />
                    Donate with Revolut
                </button>
            </div>
            <div className="donation-questions">
                <h2>Got Questions?</h2>
                <div className="questions">
                    <div className="question">
                        <h3>Why donate to the vinyl shop?</h3>
                        <p>Your donations keep our musical journey alive.</p>
                    </div>
                    <div className="question">
                        <h3>Can I cancel my donation?</h3>
                        <p>Absolutely! We offer a no-nonsense cancellation policy.</p>
                    </div>
                </div>
            </div>
            <div className="donation-quote">
                <p>"Without music, life would be a mistake." - Friedrich Nietzsche</p>
            </div>
        </div>
    );
};

export default DonationSection;
