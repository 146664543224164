import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLoginContext, useProductsContext } from "../../context";
import { productsApi, ratingsApi, spotifyApi } from "../../api";
import "./ProductDetailPage.css";

const ProductDetailPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);

  const { setProductRating } = useProductsContext();

  const { userInfo } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const fetchedProduct = await productsApi.getProduct(productId);
        const fetchedRating = await ratingsApi.getRating(productId);

        setProduct(fetchedProduct);
        setAverageRating(fetchedRating.averageRating);
        setRatingCount(fetchedRating.ratingCount);

        const tracks = await spotifyApi.getAlbumTracks(
          fetchedProduct.spotifyAlbumId
        );

        setTracks(tracks);
      } catch (error) {
        console.error("Error fetching product or rating data:", error);
      }
    };

    fetchProductData();
  }, [productId]);

  const handleRatingChange = (e) => setRating(e.target.value);

  const submitRating = async () => {
    try {
      const ratingDTO = {
        rating: Number(rating),
        productId: productId,
        userEmail: userInfo.userMail,
      };

      const response = await ratingsApi.addRating(productId, ratingDTO);

      setAverageRating(response.averageRating);
      setRatingCount(response.ratingCount);
      setProductRating(productId, response.averageRating);
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const navigateBack = () => navigate(-1);

  if (!product) return <div>Loading...</div>;

  return (
    <div className="product-detail-page">
      <h3 onClick={navigateBack} className="pointer">
        Back
      </h3>
      <div className="product-image">
        <img src={product.imageId} alt={product.name} />
      </div>
      <div className="product-info">
        <h2>{product.name}</h2>
        <p>Artist: {product.artist}</p>
        <p>Genre: {product.genre}</p>
        <p>Price: RON{product.price.toFixed(2)}</p>
        <p>Availability: {product.availability}</p>
        <p>
          Average Rating: {averageRating.toFixed(2)} ({ratingCount} ratings)
        </p>
      </div>
      {tracks.length > 0 && (
        <section className="tracks">
          Track list:
          {tracks.map(({ trackName }, index) => (
            <div key={trackName}>
              {index + 1}: {trackName}
            </div>
          ))}
        </section>
      )}
      <div className="rating-section">
        <label htmlFor="rating">Your Rating:</label>
        <input
          id="rating"
          type="number"
          min="1"
          max="5"
          step="1"
          value={rating}
          onChange={handleRatingChange}
        />
        <button onClick={submitRating}>Submit Rating</button>
      </div>
    </div>
  );
};

export default ProductDetailPage;
