import React from "react";
import styles from "./Notification.module.css";

const Notification = ({ message, gif }) => {
  return (
    <div className={styles.notification}>
      <img src={gif} alt="alternative-gif" />
      <p>{message}</p>
    </div>
  );
};

export default Notification;
