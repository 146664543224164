import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BuyerDashboard.module.css";

const BuyerDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.buyerDashboardContainer}>
      <h2>Buyer Dashboard</h2>
      <div className="dashboard-sections">
        <div className="section">
          <h3>Orders</h3>
          <p>See your orders</p>
          <button onClick={() => navigate("/buyer/orders")}>View Orders</button>
        </div>
        <div className="section">
          <h3>Your Details</h3>
          <p>Manage your personal details</p>
          <button onClick={() => navigate("/buyer/details")}>View Details</button>
        </div>
      </div>
    </div>
  );
};

export default BuyerDashboard;
