import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { productsApi } from "../api";

const initialState = {
  products: [],
  setProducts: () => {},
  fetchProducts: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  pagesCount: 1,
  pages: 1,
  paginatedProducts: [],
  genres: [],
  artists: [],
  setProductQuantity: () => {},
  removeProduct: () => {},
  setProductRating: () => {},
};

export const ProductsContext = createContext(null);

export const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState(initialState.products);
  const [paginatedProducts, setPaginatedProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [genres, setGenres] = useState([]);
  const [artists, setArtists] = useState([]);

  const productsPerPage = useMemo(() => 8, []);

  const lastProductIndex = useMemo(
    () => currentPage * productsPerPage,
    [currentPage, productsPerPage]
  );
  const firstProductIndex = useMemo(
    () => lastProductIndex - productsPerPage,
    [lastProductIndex, productsPerPage]
  );

  const pagesCount = useMemo(
    () => Math.ceil(products.length / productsPerPage),
    [products, productsPerPage]
  );

  const pages = useMemo(
    () => Array.from({ length: pagesCount }, (_, i) => i + 1),
    [pagesCount]
  );

  const fetchProducts = async () => {
    try {
      const data = await productsApi.getProducts();
      setProducts(data);
    } catch (error) {
      showBoundary(error);
    }
  };

  const setProductQuantity = async (productId, quantity) => {
    const index = products.findIndex((item) => item.id === productId);

    setProducts((prevProducts) => [
      ...prevProducts.slice(0, index),
      {
        ...prevProducts[index],
        quantity,
        availability: quantity === 0 ? "Out of stock" : "In stock",
      },
      ...prevProducts.slice(index + 1),
    ]);

    await productsApi.updateProductQuantity(productId, quantity);
  };

  const removeProduct = async (productId) => {
    await productsApi.deleteProduct(productId);
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );
  };

  const setProductRating = (productId, rating) => {
    const index = products.findIndex((item) => item.id === parseInt(productId));
    setProducts((prevProducts) => [
      ...prevProducts.slice(0, index),
      {
        ...prevProducts[index],
        rating: rating,
      },
      ...prevProducts.slice(index + 1),
    ]);
  };

  useEffect(() => {
    const paginatedData = products.slice(firstProductIndex, lastProductIndex);
    setPaginatedProducts(paginatedData);
    setArtists([...new Set(paginatedData.map((product) => product.artist))]);
    setGenres([...new Set(paginatedData.map((product) => product.genre))]);
  }, [products, firstProductIndex, lastProductIndex]);

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        fetchProducts,
        setCurrentPage,
        pages,
        paginatedProducts,
        genres,
        artists,
        setProductQuantity,
        removeProduct,
        setProductRating,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => useContext(ProductsContext);
