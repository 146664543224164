import React from "react";

import HeroSection from "./HeroSection";
import BestSeller from "./BestSeller";
import ShopComponent from "./ShopComponent";
import Youmay from "./Youmay";
import Footer from "./Footer";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <BestSeller />
      <ShopComponent />
      <Youmay />
      <Footer />
    </>
  );
};

export default HomePage;
