import axios from "axios";
import { getToken } from "../utils";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, Origin, X-Auth-Token",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
};

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders,
};

const getAxiosInstance = () => {
  const axiosInstance = axios.create(defaultConfig);
  axiosInstance.interceptors.request.use(
    (request) => {
      const token = getToken();

      request.headers["Authorization"] = token ? `Bearer ${token}` : "";
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (request) => request,
    async (error) => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};



export const getSpotifyAxiosInstance = () => {

}
export default getAxiosInstance;
