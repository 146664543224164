import { useEffect, useState } from "react";
import { useCartContext, useProductsContext } from "../context";

export const useCartProducts = () => {
  const [cartProducts, setCartProducts] = useState([]);

  const { products } = useProductsContext();
  const { cartContent } = useCartContext();

  const { cartItems, totalCost } = cartContent;

  useEffect(() => {
    !!cartItems &&
      cartItems.forEach((item) => {
        const cartProduct = products.find(
          (product) => product.id === item.productId
        );

        cartProduct.quantity = item.quantity;

        setCartProducts((previousProducts) => [
          ...previousProducts,
          cartProduct,
        ]);
      });

    return () => setCartProducts([]);
  }, [products, cartItems]);

  return { cartProducts, totalCost };
};
