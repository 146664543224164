import { useEffect, useState } from "react";
import { useLoginContext, useProductsContext } from "../context";

export const useUserProducts = (products) => {
  const [userProducts, setUserProducts] = useState([]);

  const { userInfo } = useLoginContext();

  const { userId } = userInfo;

  useEffect(() => {
    if (!userId) setUserProducts([]);
    const currentUserProducts = products.filter(
      (product) => product.sellerId === userId
    );
    setUserProducts(currentUserProducts);

    return () => setUserProducts([]);
  }, [products, userId]);

  return { userProducts };
};
