// src/context/WishlistContext.js
import React, { createContext, useState, useContext } from "react";

const WishlistContext = createContext();

export const WishlistContextProvider = ({ children }) => {
  const [wishlistItems, setWishlistItems] = useState([]);

  const addToWishlist = (item) => {
    setWishlistItems((prevItems) => [...prevItems, item]);
  };

  const removeFromWishlist = (product) => {
    setWishlistItems((prevItems) =>
      prevItems.filter(({ id }) => id !== product.id)
    );
  };

  const isInWishlist = (product) =>
    !!wishlistItems.find(({ id }) => id === product.id);

  return (
    <WishlistContext.Provider
      value={{
        wishlistItems,
        addToWishlist,
        removeFromWishlist,
        isInWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlistContext = () => useContext(WishlistContext);
