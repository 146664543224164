import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const getToken = async (credentials) => {
  try {
    const response = await getAxiosInstance().post(
      Endpoints.LOGIN,
      credentials
    );

    const token = response.data;

    return token;
  } catch (error) {
    throw new Error(error);
  }
};

const refreshSessionToken = async (token) => {
  try {
    delete getAxiosInstance().defaults.headers.common["Authorization"];
    const response = await getAxiosInstance().post(Endpoints.REFRESH, token);
    return response;
  } catch (error) {
    throw error;
  }
};

export const loginApi = {
  getToken,
  refreshSessionToken,
};
