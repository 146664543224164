import axios from "axios";
import { SpotifyEndpoints } from "./constants";

const defaultHeaders = (token) => ({
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: `Bearer ${token}`,
});

const defaultConfig = (token) => ({
  baseURL: process.env.REACT_APP_SPOTIFY_API_URL,
  headers: defaultHeaders(token),
});

const getSpotifyAxiosInstance = (token) => {
  const axiosInstance = axios.create(defaultConfig(token));
  return axiosInstance;
};

const getSpotifyToken = async () => {
  const data = `grant_type=client_credentials`;

  const encodedCredentials = btoa(
    `${process.env.REACT_APP_SPOTIFY_CLIENT_ID}:${process.env.REACT_APP_SPOTIFY_CLIENT_SECRET}`
  );

  try {
    const response = await axios
      .create({
        ...defaultConfig,
        baseURL: process.env.REACT_APP_SPOTIFY_AUTH_URL,
      })
      .post(SpotifyEndpoints.AUTH, data, {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      });

    const { access_token, token_type } = response.data;

    return { access_token, token_type };
  } catch (error) {
    return error;
  }
};

const getAlbumCoverAndId = async (albumName, artist) => {
  const query = `${artist.replace("&", "and")}${albumName}`;
  const URL = `${SpotifyEndpoints.SEARCH}?q=${encodeURIComponent(
    query
  )}&type=album&limit=1`;

  const { access_token } = await getSpotifyToken();
  try {
    const response = await getSpotifyAxiosInstance(access_token).get(URL);

    const albumImages = response.data.albums.items[0].images;

    const mediumImage = albumImages[1].url || "";

    const spotifyAlbumId = response.data.albums.items[0].id;

    return { mediumImage, spotifyAlbumId };
  } catch (error) {
    return error;
  }
};

const getAlbumTracks = async (albumId) => {
  const URL = `${SpotifyEndpoints.ALBUMS}/${albumId}${SpotifyEndpoints.TRACKS}`;

  const { access_token } = await getSpotifyToken();
  try {
    const response = await getSpotifyAxiosInstance(access_token).get(URL);

    const { items } = response.data;

    const tracks = items.reduce((acc, value) => {
      acc.push({ trackName: value.name });
      return acc;
    }, []);

    return tracks;
  } catch (error) {
    return error;
  }
};

export const spotifyApi = {
  getAlbumCoverAndId,
  getAlbumTracks,
};
