// src/Community/CommunityPage.js

import React from "react";
import DonationSection from "./DonationSection";
import "./CommunityPage.css";

const CommunityPage = () => {
  return (
    <div className="community-container">
      <div className="community-header">
        <h1>Community</h1>
        <p>
          Welcome to our community page. Connect, share, and contribute to our
          musical journey.
        </p>
      </div>
      <DonationSection />
    </div>
  );
};

export default CommunityPage;
