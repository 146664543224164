import React from "react";
import { useProductsContext } from "../../context";
import { Product } from "../../shared-components";

const ShopComponent = () => {
  const { paginatedProducts } = useProductsContext();

  const getRandomProducts = (array) => {
    return array.reduce((acc, _) => {
      const item = array[Math.floor(Math.random() * array.length)];
      if (!acc.includes(item)) acc.push(item);
      return acc;
    }, []);
  };

  return (
    <div className="shop-page">
      <h2 className="title">PRODUCTS</h2>
      <div className="shop-content">
        <div className="product-list">
          {getRandomProducts(paginatedProducts)
            .splice(0, 4)
            .map((product) => {
              const { id } = product;

              return <Product key={id} {...product} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default ShopComponent;
