import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Youmay.css";
import { useLoginContext } from "../../context";
import { Recommendations } from "../Product Detail";

const YouMayAlsoLike = () => {
  const { userInfo } = useLoginContext();

  const { userId } = userInfo;

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    centerMode: false, // This prevents the centering of slides
    centerPadding: "0px", // No padding inside the slider
  };

  const products = [
    {
      id: 1,
      imageUrl: require("../../Pictures/Nirvana.jpeg"),
      link: "#",
      name: "Nirvana Album",
    },
    {
      id: 2,
      imageUrl: require("../../Pictures/Michael.jpeg"),
      link: "#",
      name: "Michael Album",
    },
    {
      id: 3,
      imageUrl: require("../../Pictures/DeepPurple.jpeg"),
      link: "#",
      name: "Deep Purple Album",
    },
    {
      id: 4,
      imageUrl: require("../../Pictures/Britney.jpeg"),
      link: "#",
      name: "Britney Album",
    },
    {
      id: 5,
      imageUrl: require("../../Pictures/ACDC.jpeg"),
      link: "#",
      name: "ACDC Album",
    },
    {
      id: 6,
      imageUrl: require("../../Pictures/Amy.jpeg"),
      link: "#",
      name: "Amy Album",
    },
    {
      id: 7,
      imageUrl: require("../../Pictures/Elvis.jpeg"),
      link: "#",
      name: "Elvis Album",
    },
    {
      id: 8,
      imageUrl: require("../../Pictures/Erika.jpeg"),
      link: "#",
      name: "Erika Album",
    },
    {
      id: 9,
      imageUrl: require("../../Pictures/Olivia.jpeg"),
      link: "#",
      name: "Olivia Album",
    },
    {
      id: 10,
      imageUrl: require("../../Pictures/Yungblud.jpeg"),
      link: "#",
      name: "Yungblud Album",
    },
  ];

  return (
    <div className="you-may-also-like">
      <h2>YOU MAY ALSO LIKE</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="slide">
            <img src={product.imageUrl} alt={product.name} />
            <a href="/shop" className="buy-button">
              Buy Now
            </a>
          </div>
        ))}
      </Slider>

      {!!userId && <Recommendations userId={userId} />}
    </div>
  );
};

export default YouMayAlsoLike;
