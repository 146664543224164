import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const getFact = async (date) => {
  try {
    const response = await getAxiosInstance().get(
      `${Endpoints.FACTS}/${date}`
    );

    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    return {};
  } catch (error) {
    return error;
  }
};

export const factsApi = {
  getFact,
};
