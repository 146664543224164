import React from "react";
import { useNavigate } from "react-router-dom";
import "./SellerDashboard.css";

const SellerDashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="seller-dashboard">
        <h2>Seller Dashboard</h2>
        <div className="dashboard-sections">
          <div className="section">
            <h3>Products</h3>
            <p>Manage your products.</p>
            <button onClick={() => navigate("/seller/products")}>
              View Products
            </button>
          </div>
          <div className="section">
            <h3>Orders</h3>
            <p>Manage received orders.</p>
            <button onClick={() => navigate("/seller/orders")}>
              View Orders
            </button>
          </div>
          <div className="section">
            <h3>Inventory</h3>
            <p>Monitor stock levels.</p>
            <button onClick={() => navigate("/seller/inventory")}>
              View Inventory
            </button>
          </div>
          {/* <div className="section">
            <h3>Discounts</h3>
            <p>Apply and manage discounts.</p>
            <button>View Discounts</button>
          </div> */}
          {/* <div className="section">
            <h3>Customers</h3>
            <p>View customer information.</p>
            <button onClick={() => navigate("/seller/customers")}>
              View Customers
            </button>
          </div> */}
          <div className="section">
            <h3>Reports</h3>
            <p>View reports and statistics.</p>
            <button onClick={() => navigate("/seller/reports")}>
              View Reports
            </button>
          </div>
          {/* <div className="section">
            <h3>Settings</h3>
            <p>Manage your account settings.</p>
            <button>View Settings</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SellerDashboard;
