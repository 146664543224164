// src/Sign In/SignInSignUpPage.js
import React, { useState } from "react";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import "./SignInSignUpPage.css";

const SignInSignUpPage = () => {
  const [isSignIn, setIsSignIn] = useState(true);

  return (
    <div>
      <div className="container">
        <div className="toggle-buttons">
          <button
            onClick={() => setIsSignIn(true)}
            className={isSignIn ? "active" : ""}
          >
            Sign In
          </button>
          <button
            onClick={() => setIsSignIn(false)}
            className={!isSignIn ? "active" : ""}
          >
            Sign up
          </button>
        </div>
        <div className="form-wrapper">
          {isSignIn ? <SignInForm /> : <SignUpForm />}
        </div>
      </div>
    </div>
  );
};

export default SignInSignUpPage;
