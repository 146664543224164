import React, { useEffect, useState } from "react";
import "./Recommendations.css";
import { ratingsApi } from "../../api";
import { Product } from "../../shared-components";
import { useProductsContext } from "../../context";

const Recommendations = ({ userId }) => {
  const [recommendations, setRecommendations] = useState([]);

  const { products } = useProductsContext();

  useEffect(() => {
    const fetchRecommendations = async () => {
      const data = await ratingsApi.getRecommendations(userId);

      setRecommendations(data);
    };

    fetchRecommendations();
  }, [userId, products]);

  return (
    <div className="recommendations">
      <h2>Recommended for You</h2>
      <div className="recommendation-list">
        {recommendations.length > 0 &&
          recommendations.map((productId) => {
            const product = products.find((item) => item.id === productId);

            if (!product) return;

            return <Product key={productId} {...product} />;
          })}
      </div>
    </div>
  );
};

export default Recommendations;
