import { createContext, useContext, useState } from "react";
import { cartApi } from "../api";
import { getToken } from "../utils";

const initialState = {
  cartContent: {
    cartItems: [],
    totalCost: 0,
    status: "",
  },
  addCartItem: () => {},
  fetchCartItems: () => {},
  removeCartItem: () => {},
  removeAllCartItems: () => {},
  increaseCartItemQuantity: () => {},
  decreaseCartItemQuantity: () => {},
};

export const CartContext = createContext(null);

export const CartContextProvider = ({ children }) => {
  const [cartContent, setCartContent] = useState(initialState.cartContent);

  const addCartItem = async (productId, quantity) => {
    const item = cartContent?.cartItems?.find(
      (item) => item.productId === productId
    );

    const data = await cartApi.addCartItem(
      productId,
      !!item?.quantity ? item?.quantity + 1 : quantity
    );

    setCartContent((previousCartItems) => ({
      ...previousCartItems,
      cartItems: data.cartItems,
      totalCost: data.totalCost,
      status: data.status,
    }));
  };

  const removeAllCartItems = () => setCartContent(initialState.cartContent);

  const removeCartItem = async (productId) => {
    const data = await cartApi.removeCartItem(productId);

    setCartContent((previousCartItems) => ({
      ...previousCartItems,
      cartItems: data.cartItems,
      totalCost: data.totalCost,
      status: data.status,
    }));
  };

  const fetchCartItems = async () => {
    let data = [];
    if (getToken()) {
      data = await cartApi.getCartItems();
    }
    setCartContent((previousData) => ({
      ...previousData,
      cartItems: data.cartItems,
      totalCost: data.totalCost,
      status: data.status,
    }));
  };

  const setCartItemQuantity = async (productId, quantity) => {
    const updatedCart = await cartApi.updateCartItem(productId, quantity);

    setCartContent((previousCartItems) => ({
      ...previousCartItems,
      cartItems: updatedCart.cartItems,
      totalCost: updatedCart.totalCost,
      status: updatedCart.status,
    }));
  };

  const increaseCartItemQuantity = async (productId) => {
    const cartProduct = cartContent.cartItems.find(
      (item) => item.productId === productId
    );

    await setCartItemQuantity(productId, cartProduct.quantity + 1);
  };

  const decreaseCartItemQuantity = async (productId) => {
    const cartProduct = cartContent.cartItems.find(
      (item) => item.productId === productId
    );

    if (cartProduct.quantity - 1 <= 0) return;

    await setCartItemQuantity(productId, cartProduct.quantity - 1);
  };

  return (
    <CartContext.Provider
      value={{
        cartContent,
        addCartItem,
        fetchCartItems,
        removeAllCartItems,
        removeCartItem,
        increaseCartItemQuantity,
        decreaseCartItemQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);
