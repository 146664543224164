import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SignInSignUpPage.css";
import { Button } from "../../shared-components/Button";
import { useLoginContext } from "../../context";

const SignInForm = () => {
  const [error, setError] = useState("");

  const { setCredentials, handleLogin } = useLoginContext();

  const navigate = useNavigate();

  const loginAndNavigate = async (event) => {
    const isLoginSuccessful = await handleLogin(event);
    if (!isLoginSuccessful) {
      setError("Invalid credentials; please try again");
    } else {
      setError("");
      navigate("/home");
    }
  };

  return (
    <form onSubmit={loginAndNavigate} className="sign-in-form">
      <h2>Sign In</h2>
      <label>Email:</label>
      <input
        type="email"
        onChange={(e) =>
          setCredentials((prevCredentials) => ({
            ...prevCredentials,
            email: e.target.value,
          }))
        }
        required
      />
      <label>Password:</label>
      <input
        type="password"
        onChange={(e) =>
          setCredentials((prevCredentials) => ({
            ...prevCredentials,
            password: e.target.value,
          }))
        }
        required
      />
      {error !== "" && <div style={{ color: "red" }}>{error}</div>}

      <Button type="submit" text="Sign in" />
    </form>
  );
};

export default SignInForm;
