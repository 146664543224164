// src/About/AboutPage.js

import React from "react";
import "./AboutPage.css";
import vinylImage from "../../Pictures/vinyl.jpg"; // Make sure you have a vinyl image in your Images folder

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About Thunder Time Records</h1>
      <div className="about-content">
        <div className="about-text">
          <p>
            Thunder Time Records was born from a passion for vinyl records and a
            love for music. In Romania, where I come from, there isn't a
            dedicated platform for vinyl enthusiasts. As a passionate vinyl
            collector and a Computer Science student in my final year at the
            Technical University of Cluj-Napoca, I wanted to combine my love for
            computer science with my love for music.
          </p>
          <p>
            My vision was to create a space where all vinyl lovers can come
            together, share their passion, buy, and sell records. Thunder Time
            Records is more than just a marketplace; it's a community for those
            who appreciate the beauty and quality of vinyl records.
          </p>
          <p>
            Thank you for being a part of this journey. Together, let's keep the
            vinyl spirit alive!
          </p>
          <p> - Daria Francioli</p>
        </div>
        <div className="about-image">
          <img src={vinylImage} alt="Vinyl Record" />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
