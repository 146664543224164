import React, { useState, useEffect } from "react";
import "./OrderManagement.css";
import { ordersApi } from "../../api";
import { useLoginContext } from "../../context";

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);

  const { userInfo } = useLoginContext();

  const { userRole, userId } = userInfo;

  useEffect(() => {
    const fetchOrders = async () => {
      let dbOrders = [];

      if (userRole === "SELLER") dbOrders = await ordersApi.getOrders();
      else dbOrders = await ordersApi.getOrdersForBuyer(userId);

      setOrders(dbOrders);
    };

    fetchOrders();
  }, [userId, userRole]);

  return (
    <div className="order-management-container">
      <h2>Manage Orders</h2>
      <ul>
        {orders.map((order) => (
          <li key={order.id}>
            Order #{order.id} - Total: {order.totalPrice}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderManagement;
