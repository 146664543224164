import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const getTransactions = async () => {
  try {
    const response = await getAxiosInstance().get(
      `${Endpoints.PAYMENTS}${Endpoints.TRANSACTIONS}`
    );

    if (response.status >= 200 && response.status <= 299) return response.data;

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const paymentApi = {
  getTransactions,
};
