import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { generatePassword } from "../../utils/passwordGenerator";
import { usersApi } from "../../api";
import "./SignUpForm.css";
import { useLoginContext } from "../../context";
import { Button } from "../../shared-components";

const SignUpForm = () => {
  const [role, setRole] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    telephone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { handleToken } = useLoginContext();

  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole);
    if (selectedRole === "SELLER") {
      navigate("/seller-register");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      const result = zxcvbn(value);
      setPasswordStrength(result.score);
    }
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    setFormData({ ...formData, password });
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await usersApi.registerUser({ ...formData, role });

    if (result.success) {
      handleToken(result.token);
      role === "SELLER" ? navigate("/seller-dashboard") : navigate("/shop");
    }
  };

  return (
    <div className="signup-form-container">
      {role ? (
        <form onSubmit={handleSubmit} className="signup-form">
          <h2>Sign Up</h2>
          <label htmlFor="firstName">First Name:</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="telephone">Telephone:</label>
          <input
            type="text"
            name="telephone"
            value={formData.telephone}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="addressLine1">Address Line 1:</label>
          <input
            type="text"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="addressLine2">Address Line 2:</label>
          <input
            type="text"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleInputChange}
          />
          <label htmlFor="city">City:</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="state">State:</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="country">Country:</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="zipCode">Zip Code:</label>
          <input
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="password" className="password-label">
            Password
          </label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <span className="password-toggle" onClick={toggleShowPassword}>
              {showPassword ? (
                <i className="fas fa-eye-slash"></i>
              ) : (
                <i className="fas fa-eye"></i>
              )}
            </span>
          </div>
          <span className="generate-password" onClick={handleGeneratePassword}>
            Generate Strong Password
          </span>
          {formData.password.length > 0 && (
            <div
              className={`password-strength ${
                ["weak", "medium", "strong", "strong", "strong"][
                  passwordStrength
                ]
              }`}
            >
              {
                [
                  "Weak password",
                  "Medium password",
                  "Good password",
                  "Strong password",
                  "Very strong password",
                ][passwordStrength]
              }
            </div>
          )}
          <Button text="Register!" type="submit" />
        </form>
      ) : (
        <div className="role-selection">
          <h2>Select Your Role</h2>
          <Button
            text="Buyer"
            type="submit"
            onClick={() => handleRoleSelection("BUYER")}
          />
          <Button
            text="Seller"
            type="submit"
            onClick={() => handleRoleSelection("SELLER")}
          />
        </div>
      )}
    </div>
  );
};

export default SignUpForm;
